.floating-button-container {
    position: fixed;
    bottom: -100px; // Initially hidden below the screen
    right: 10px;
    z-index: 9999;
    animation: slide-up 1s ease-out forwards, rotate-in 1s ease-out 1s; // Slide up and rotate
  
    .floating-button {
      background-color: #007bff;
      color: white;
      border: none;
      border-radius: 4px;
      width: 250px;
      height: 40px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
      cursor: pointer;
      font-family: Helvetica, sans-serif;
      font-size: 14px;
      font-weight: bold;
      animation: pulse 2s infinite; // Add pulsing animation
      transform-origin: center;
  
      &:hover {
        background-color: #0056b3;
      }
    }
  }
  
  // Keyframes for pulsing effect
  @keyframes pulse {
    0% {
      transform: scale(1);
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    }
    50% {
      transform: scale(1.1);
      box-shadow: 0 6px 10px rgba(0, 0, 0, 0.3);
    }
    100% {
      transform: scale(1);
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    }
  }
  
  // Slide-Up Animation
  @keyframes slide-up {
    0% {
      bottom: -100px; // Start off-screen
    }
    100% {
      bottom: 150px; // Final position
    }
  }
  
  // Rotate-In Animation
  @keyframes rotate-in {
    0% {
      transform: rotate(-360deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }

/* Button styling */
#getCallButton {
  position: absolute;
  right: 6px;
  top: 5px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 2px;
  padding: 6px 9px;
  cursor: pointer;
  font-size: 14px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 9998;
  font-family: Helvetica, sans-serif;
  animation: colorChange 2s infinite;

  &:hover {
    background-color: #0056b3;
  }
}

/* Animation for color changing */
@keyframes colorChange {
  0% {
    background-color: #007bff;
  }
  25% {
    background-color: #28a745;
  }
  50% {
    background-color: #ffc107;
  }
  75% {
    background-color: #dc3545;
  }
  100% {
    background-color: #007bff;
  }
}

/* Sliding window styling */
#callFormWindow {
  position: fixed;
  right: -300px;
  top: 4px;
  width: 250px;
  background-color: #f2f4f7;
  border: 1px solid #ccc;
  box-shadow: -2px 0 6px rgba(0, 0, 0, 0.2);
  transition: right 0.3s ease;
  // padding: 20px;
  z-index: 9999;

  &.active {
    right: 5px;
  }

  h3 {
    margin: 0 0 15px;
    font-size: 18px;
    color: #333;
  }

  input[type="text"] {
    width: 92%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
    height: 40px;
    margin: 10px;
  }

  button {
    width: 53%;
    padding: 10px;
    background-color: #C92527;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    margin: 10px;
    text-transform: capitalize!important;

    &:hover {
      background-color: #C92527;
    }
  }
}

/* Close icon styling */
#closeIcon {
  position: absolute;
  top: -0px;
  right: 0px;
  font-size: 26px;
  font-weight: bold;
  color: #ff0000;
  cursor: pointer;

  &:hover {
    color: #ffffff;
  }
}
#getCallButtonx{
  position: fixed;
  right: 6px;
  top: 4px;
  z-index: 9998;
  cursor: pointer;
}

@media screen and (min-width: 200px) and (max-width: 1199px) {
  // Slide-Up Animation
  @keyframes slide-up {
    0% {
      bottom: -100px; // Start off-screen
    }
    100% {
      bottom: 70px; // Final position
    }
  }
}

  