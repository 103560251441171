// Style for invalid input fields
.error-input {
    border: 2px solid red !important;
    background-color: #ffe6e6 !important;
  }
  
  // Style for error messages
  .error-text {
    color: red;
    font-size: 12px;
    margin-top: 5px;
    display: block;
  }
  