// $gradient-primary: linear-gradient(-90deg, #db261f 0%, #2d1c70 100%);
$gradient-primary: rgba(204, 179, 179, 0.179);

.custom-single-drop {
    padding: 0 !important;

    li {
        padding: 0.8rem 0;
        border-bottom: 2px solid rgba(0, 0, 0, 0.05);

        &:hover {
            background: $gradient-primary !important;
        }

        a{
            font-size: small!important;
            white-space: nowrap;
            display: -webkit-box!important;
            // -webkit-line-clamp: 1!important; 
            -webkit-box-orient: vertical!important;
            overflow: hidden!important;
            text-overflow: ellipsis;
        }
    }
}

.custom-single-modile-drop {
    padding: 0 !important;

    li {
        padding: 0rem 0.8rem;
        border-bottom: 2px solid rgba(0, 0, 0, 0.05);

        &:hover {
            background: $gradient-primary !important;
        }

        a{
            font-size: small!important;
            white-space: nowrap;
            display: -webkit-box!important;
            // -webkit-line-clamp: 1!important; 
            -webkit-box-orient: vertical!important;
            overflow: hidden!important;
            text-overflow: ellipsis;
        }
    }
}

.custom-megamenu-drop{
    padding: 0!important;

    li{
        padding: 2rem 2rem;

        a{
            font-weight: 600!important;
            color: black!important;
        }
    }
}

.custom-megamenu-drop-width{
    width: 50%!important;
    background: none;
    padding-right: 0!important;
}