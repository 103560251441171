.card-books {
    position: relative;
    width: fit-content;
    background-color: #f2f2f2;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    perspective: 1000px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
    transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    border-radius: 2px!important;
  
    svg {
      width: 48px;
      fill: #333;
      transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }
  
    &:hover {
      transform: scale(1.05);
      box-shadow: 0 8px 16px rgba(255, 255, 255, 0.2);
  
      svg {
        scale: 0;
      }
  
      .card__content {
        transform: rotateX(0deg);
      }
    }
  
    .card__content {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 20px;
      box-sizing: border-box;
      background-color: #f8f8fc;
      transform: rotateX(-90deg);
      transform-origin: bottom;
      transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  
    //   .card__title {
    //     margin: 0;
    //     font-size: 24px;
    //     color: #333;
    //     font-weight: 700;
    //   }
  
      .card__description {
        margin: 10px 0 0;
        font-size: 14px;
        color: #777;
        line-height: 1.4;
      }
    }
  }
  