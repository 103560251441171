.calendar {
    // width: 320px;
    margin: 20px auto;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  
    .calendar-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1vmax 2vmax;
      background-color: #333;
      color: white;
  
      h2 {
        margin: 0;
        color: #fff;
        font-size: 2em;
        cursor: pointer;
      }
  
      button {
        background: none;
        border: none;
        color: white;
        font-size: 1.9rem;
        cursor: pointer;
      }
    }
  
    .calendar-body {
      padding: 10px;
  
      .calendar-weekdays, 
      .calendar-days {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        text-align: center;
    }
    
    .calendar-weekdays {
        div {
            font-weight: bold;
            padding: 5px 0;
            font-size: 1.4rem;
        }
    }
    
    .calendar-days {
        margin-top: 10px;
        
        .calendar-day {
            border: 2px solid rgba(0, 0, 0, 0.1);
            padding: 10px;
            cursor: pointer;
            font-size: 1.4rem;
            transition: background-color 0.3s;
            border-radius: 4px;
            
            &:hover {
              border: 2px solid transparent;
              // color: #fff;
            background-image: linear-gradient(to right, #f9b8b6a3, #b2a8dbc9), linear-gradient(to right, #db261f, #2d1c70); /* Inner and border gradients */
  background-origin: border-box;
  background-clip: padding-box, border-box;
            background-color: #e0e0e0;
          }
        }
      }
    }
  
    .today {
      border: 0px solid rgba(0, 0, 0, 0)!important;
      background-image: linear-gradient(-90deg, #db261f 0%, #2d1c70 100%)!important;
      color: white;
      border-radius: 50%;
    }

    .month-picker {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 0px;
            padding: 10px;
        
            .month {
              padding: 10px;
              border: 2px solid rgba(0, 0, 0, 0.1);
              cursor: pointer;
              font-weight: 600;
              font-size: 1.3rem;
              transition: background-color 0.3s;
              text-align: center;
              border-radius: 4px;
        
              &.selected-month {
                // background-color: #4a90e2;
                border: 0px solid rgba(0, 0, 0, 0.1);
                background-image: linear-gradient(-90deg, #db261f 0%, #2d1c70 100%)!important; 
                color: white;
              }
        
              &:hover {
                border: 2px solid transparent;
                // color: #fff;
              background-image: linear-gradient(to right, #f9b8b6a3, #b2a8dbc9), linear-gradient(to right, #db261f, #2d1c70); /* Inner and border gradients */
    background-origin: border-box;
    background-clip: padding-box, border-box;
              background-color: #e0e0e0;
              }
            }
          }
  }


// .calendar {
//     width: 320px;
//     margin: 20px auto;
//     border: 1px solid #ddd;
//     border-radius: 8px;
//     background-color: #fff;
//     box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
//     overflow: hidden;
//     position: relative;
  
//     .calendar-header {
//       display: flex;
//       justify-content: space-between;
//       align-items: center;
//       padding: 10px;
//       background-color: #f0f0f0;
//       color: #333;
  
//       h2 {
//         margin: 0;
//         font-size: 1.2em;
//         cursor: pointer;
//       }
  
//       button {
//         background: none;
//         border: none;
//         color: #333;
//         font-size: 1.2em;
//         cursor: pointer;
//       }
//     }
  
//     .calendar-body {
//       padding: 10px;
  
//       .calendar-weekdays, 
//       .calendar-days {
//         display: grid;
//         grid-template-columns: repeat(7, 1fr);
//         text-align: center;
//       }
  
//       .calendar-weekdays {
//         div {
//           font-weight: bold;
//           padding: 5px 0;
//         }
//       }
  
//       .calendar-days {
//         margin-top: 10px;
  
//         .calendar-day {
//           padding: 10px;
//           cursor: pointer;
//           transition: background-color 0.3s;
//           border-radius: 4px;
  
//           &:hover {
//             background-color: #e0e0e0;
//           }
//         }
//       }
//     }
  
//     .today {
//       background-color: #333;
//       color: white;
//       border-radius: 50%;
//     }
  
//     .month-picker {
//       display: grid;
//       grid-template-columns: repeat(3, 1fr);
//       gap: 10px;
//       padding: 10px;
  
//       .month {
//         padding: 10px;
//         cursor: pointer;
//         transition: background-color 0.3s;
//         text-align: center;
//         border-radius: 4px;
  
//         &.selected-month {
//           background-color: #4a90e2;
//           color: white;
//         }
  
//         &:hover {
//           background-color: #e0e0e0;
//         }
//       }
//     }
//   }
  
  