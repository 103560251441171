// $form-background: linear-gradient(45deg, skyblue, darkblue);
// $form-hover-background: linear-gradient(45deg, darkblue, skyblue);
$input-border: 1px solid #ccc;
$input-focus-border: 1.5px solid rgb(3, 26, 156);
$button-shadow-color: royalblue;

.form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: #F7F7FF;
  // padding: 30px;
  width: 300px;
  border-radius: 10px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  transition: background 0.3s ease;
  border: 1px solid #ccc;
  margin: 50px;

  &:hover {
    background-color: #F7F7FF;
  }

  & button {
    align-self: flex-end;
  }
}

::placeholder {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.flex-column {
  > label {
    color: white;
    font-weight: 600;
  }
}

.inputForm {
  border: $input-border;
  border-radius: 10px;
  height: 50px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  transition: 0.2s ease-in-out;
  background-color: white;
  margin-top: 10px;

  &:focus-within {
    border: $input-focus-border;
  }

  .input {
    margin-left: 10px;
    border-radius: 10rem;
    border: none;
    width: 100%;
    height: 100%;

    &:focus {
      outline: none;
    }
  }
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  justify-content: space-between;

  > div > label {
    font-size: 14px;
    color: black;
    font-weight: 400;
  }
}

.span {
  font-size: 14px;
  margin-left: 5px;
  color: white;
  font-weight: 500;
  cursor: pointer;
}

.button-submit {
  position: relative;
  display: inline-block;
  padding: 15px 30px;
  text-align: center;
  letter-spacing: 1px;
  text-decoration: none;
  background: transparent;
  transition: ease-out 0.5s;
  border: 2px solid;
  border-radius: 10px;
  box-shadow: inset 0 0 0 0 blue;
  margin: 20px 0 10px 0;
  color: white;
  font-size: 15px;
  font-weight: 500;
  height: 50px;
  width: 100%;
  cursor: pointer;

  &:hover {
    color: white;
    box-shadow: inset 0 -100px 0 0 $button-shadow-color;
  }

  &:active {
    transform: scale(0.9);
  }
}

.p {
  text-align: center;
  color: white;
  font-size: 14px;
  margin: 5px 0;
}

.btn {
  margin-top: 10px;
  width: 100%;
  height: 50px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  gap: 10px;
  border: 1px solid #ededef;
  background-color: white;
  cursor: pointer;
  transition: 0.2s ease-in-out;

  &:hover {
    border: 1px solid rgb(17, 0, 255);
  }
}
