.loading{
    width: 100%;
    height: 50vh;
    background-color: white;
    display: grid;
    place-items: center;
    max-width: 100%;
}

.loading img{
    position: absolute;
    font-size: 30px;
}

.loading > div{
  width: 5vmax;
  height: 5vmax;
  border-bottom: 5px solid rgba(8, 8, 8, 0.788);
  border-radius: 50%;
  animation: loadingRotate 800ms linear infinite;
}

@keyframes loadingRotate {
    to{
        transform: rotateZ(-360deg);
    }
}