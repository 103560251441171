@import url('https://fonts.googleapis.com/css2?family=Playwrite+CO+Guides&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
.custom-box{
    width: 60%;
    // max-width: 80%!important;
    flex-wrap: wrap; 
}

.responsive-image {
    width: 400px;
    aspect-ratio: 400 / 280; /* Maintains the aspect ratio */
    // object-fit: cover; /* Ensures the image fills the specified width */
  }


.responsive-image1 {
    width: 400px;
    aspect-ratio: 400 / 280; /* Maintains the aspect ratio */
    // object-fit: cover; /* Ensures the image fills the specified width */
  }

  .custom-padding{
    // padding: 0.7rem 2rem;
    // padding-top: 0rem!important;
    // padding: 0.2rem!important;
  }

  .custom-btn{
    // background-color:#2d1c70cd;
    // border-radius: 4px;
    // font-weight: 600;
    border: 0px solid transparent;
    padding: 0.7rem 2rem;
  }

  .highlight-custom-background{
    background-image: linear-gradient(-90deg, #db261f 0%, #2d1c70 100%)!important; 
  }

  .highlight-modal-custom-background{
      background: #2d1c70!important; 
  }

  .custom-hover :hover{
    color: #6e6884!important;
  }
  .custom-hover{
    &:hover{
      color: red!important;
    }
  }

  .custom-course-dropdown{
    // width: 300px;
    visibility: visible!important;
    opacity: 1!important;
    // background: aqua;
    top: 15px!important;
    // position: absolute;
    transform: translateX(270px);
  }

  .custom-contenthome{
    background: #fff;
    min-height: 300px!important;
  }

  .custom-content{
    background: #fff;
    min-height: 200px!important;
  }

  .custom-content1{
    min-height: 235px!important;
  }

  /* Add this CSS to your stylesheet */
.hidden-submenu {
  opacity: 0!important;
  visibility: hidden!important;
}

.hiddenmobile-submenu{
  display: block!important;
}

.custom-tag{
  text-transform: uppercase;
  background-color: #fff!important;
  color: black;
  border: 0.5px solid rgba(0,0,0,0.3);
  padding: 0.7rem!important;
  margin: 0.5rem!important;
}

  .short-custom-btn1{
    border: 2px solid transparent;
    // color: #fff;
    border-radius: 8px;
  // background-image: linear-gradient(to right, #f9b8b6a3, #b2a8dbc9), linear-gradient(to right, #db261f, #2d1c70); /* Inner and border gradients */
background-origin: border-box;
background-clip: padding-box, border-box;
  background-color: #60cc7f;
    padding: 0.4rem 2rem;
    color: #ffffff;
  }

  .custom-badge{
    background-color:#95fcb19e!important;
    padding: 0.5rem!important;
    color: #2d1c70cd!important;
    font-size: 1.2rem!important;
  }

  .custom-badge1{
    background-color:#95fcb19e!important;
    padding: 0.5rem!important;
    color: #2d1c70cd!important;
    font-size: 1rem!important;
  }
  .custom-badge2{
    background-color:#95fcb19e!important;
    padding: 0.5rem!important;
    color: #2d1c70cd!important;
    font-size: 1.1rem!important;
    border-radius: 5px;
  }

  .custom-icon-design{
    border: 1px solid rgba(255,255,255,255)!important;

    &:hover{
      border: 2px solid rgba(255,255,255,255)!important;
    }
  }


.custom-heading{
    // display: -webkit-box!important;
    // -webkit-line-clamp: 2!important; 
    // -webkit-box-orient: vertical!important;
    // overflow: hidden!important;
    // text-overflow: ellipsis;
}

.custom-heading1{
    display: -webkit-box!important;
    -webkit-line-clamp: 1!important; 
    -webkit-box-orient: vertical!important;
    overflow: hidden!important;
    text-overflow: ellipsis;
}

.custom-paragraph1{
  display: -webkit-box!important;
  -webkit-line-clamp: 3!important; 
  -webkit-box-orient: vertical!important;
  overflow: hidden!important;
  text-overflow: ellipsis;
}

.custom-scrollingup-tab{
   position: relative;
   width: inherit;
   max-width: 100%;
}

.custom-scrollingdown-tab{
   position: fixed;
   width: inherit;
  //  z-index: 10; 
   z-index: 999; 
   max-width: 76%;
   background-color: #fff;
   box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.5);
  //  top: 90px;
   top: 0px;
   right: 0;
}

.custom-icon-hide{

  &:after{
    content: '';
  }
}

.table-custom-border{
  border: 2px solid transparent;
  // color: #fff;
background-image: linear-gradient(to right, #f3f3f3fc, #ffffff), linear-gradient(to right, #db261f, #2d1c70); /* Inner and border gradients */
background-origin: border-box;
background-clip: padding-box, border-box;
background-color: #e0e0e0;
}

.customoverflowClass{
  height: 250px;
  overflow-y: scroll;
}

.backgroundtransparent{
  mix-blend-mode: color-burn;
}

/* Custom scrollbar for modern browsers */
.customoverflowClass {
  scrollbar-width: thin; /* Firefox */
  scrollbar-color: #888 #f1f1f1; /* Thumb and Track color for Firefox */
}

.customoverflowClass::-webkit-scrollbar {
  width: 0px; /* Scrollbar width */
}

.customoverflowClass::-webkit-scrollbar-track {
  background: #f1f1f1; /* Track color */
}

.customoverflowClass::-webkit-scrollbar-thumb {
  background-color: #888; /* Thumb color */
  border-radius: 10px; /* Roundness */
  border: 3px solid #f1f1f1; /* Optional: adds padding around thumb */
}

.customoverflowClass::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Color when hovered */
}

.custom-font-family{
  font-family: 'Roboto', 'Proxima Nova', 'Proxima Nova Fallback' ,'sans-serif'!important;
}
.custom-font-family1{
  font-family: 'Proxima Nova', 'Proxima Nova Fallback' ,'sans-serif'!important;
}
.greyscale {
  filter: grayscale(1)!important;

  &:hover {
    filter: none!important;
    transition: all 0.3s ease-in-out;
    transform: scale(1.3);

  }
}

.cross-line {
  position: relative;
  color: #666666; /* Text color */
  font-size: 15px;
  display: inline-block;
  line-height: 1; /* Ensures line alignment with text */
font-weight:normal;
}

.cross-line::before {
  content: '';
  position: absolute;
  top: 65%; /* Vertically centers the line over the text */
  left: 0;
  width: 100%;
  height: 1px; /* Thickness of the line */
  background-color: red; /* Line color */
  transform: rotate(-6deg); /* 45-degree rotation */
  transform-origin: left center; /* Ensures proper pivot for rotation */
}

@media screen and (max-width: 1400px) {
  .custom-scrollingdown-tab{
    position: fixed;
    width: inherit;
    z-index: 999; 
    max-width: 100%;
    background-color: #fff;
    box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.5);
    top: 0px;
    left: 0;
 }
}

@media screen and (max-width: 600px) {
  .responsiveoverflowtableClass{
    width: 100%;
    overflow: scroll;
  }

  .responsiveoverflowtableClass table{
    width: 100%;
    white-space: nowrap;
  }

  .custom-modal{
    width: 100vw!important;
  }
}







.multi-level-menu {
  max-width: 300px;
  // margin: 20px auto;
  background: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
}

.menu {
  list-style: none;
  margin: 0;
  padding: 0;
}

.menu-item {
  border-bottom: 1px solid #ddd;
}

.menu-item:last-child {
  border-bottom: none;
}

.menu-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 16px;
  color: #333;
  background-color: #f9f9f9;
  transition: background-color 0.2s ease-in-out;
}

.menu-title:hover {
  background-color: #f1f1f1;
}

.has-submenu .arrow {
  font-size: 12px;
  color: #666;
}

.submenu {
  padding-left: 15px;
  background-color: #fafafa;
}

.submenu .menu-title {
  font-size: 14px;
  padding: 8px 15px;
}














.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-bs-toggle {
  background-color: #ffffff; 
  border: 0px solid #ddd;
  padding: 10px 20px;
  cursor: pointer;
}

.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  // right: 0; /* Submenu on the right */
  background-color: #ffffff;
  border: 0px solid #ddd;
  // min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  min-width: 180px;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

.dropdown-menu1 {
  display: none;
  position: absolute;
  top: 100%;
  // right: 0; /* Submenu on the right */
  background-color: #ffffff;
  border: 0px solid #ddd;
  // min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  min-width: 395px;
}

.dropdown-menu-after-click{
  display: none!important;
}

.sub-dropdown {
  position: relative;
}

.sub-dropdown-toggle {
  background-color: inherit;
  border: none;
  padding: 0px 20px;
  cursor: pointer;
  color: black!important;
}

.dropdown:hover .sub-dropdown-toggle:hover{
  color: red!important;
}

.sub-dropdown-toggle-active{
  color: red!important;
}

.sub-dropdown-toggle-active-course{
  color: red!important;
}

.sub-dropdown-toggle-course{
  color: black!important;
}

.sub-dropdown-menu {
  display: none;
  position: absolute;
  top: -40%;
  left: 100%;
  // right: -400px; /* Submenu on the right */
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  min-width: 400px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  font-weight: 500!important;
} 

.sub-dropdown:hover .sub-dropdown-menu {
  display: block;
}

.responsive-mobile-custom-multilevel-dropdown{
  display: none;
  top: 50px;
}

@media screen and (max-width: 1198px){
  .responsive-custom-multilevel-dropdown{
     display: none!important;
  }

  .responsive-mobile-custom-multilevel-dropdown{
     display: none;
  }

  .dropdown:hover .dropdown-menu1 {
    display: block;
  }
}