.animated-gradient-background {
    // background: linear-gradient(-90deg, #650202bc, #e9dfdce1, #f2c4ade2);
    // background: linear-gradient(-90deg, #29282865, #38373841);
    background: rgba($color: #000000, $alpha: 0.9);
    // background-size: 300% 300%;
    // animation: gradientAnimation 10s ease infinite;
  
    // Define keyframes directly within the selector for clarity
    @keyframes gradientAnimation {
      0% {
        background-position: 0% 50%;
      }
      50% {
        background-position: 100% 50%;
      }
      100% {
        background-position: 0% 50%;
      }
    }
  }

.animated-gradient-background1 {
    // background: linear-gradient(-90deg, #650202bc, #e9dfdce1, #f2c4ade2);
    // background: linear-gradient(135deg, #E0F7FA 0%, #FFFFFF 100%);
    background: linear-gradient(135deg, #F2F2FF 0%, #ffffff 100%);
    // background: linear-gradient(-90deg, #f58e8abc, #2d1c70cd, #41403ee2), url(../assets/images/blog/blog-01.png);
    background-size: 300% 300%;
    animation: gradientAnimation 10s ease infinite;
  
    // Define keyframes directly within the selector for clarity
    @keyframes gradientAnimation {
      0% {
        background-position: 0% 50%;
      }
      50% {
        background-position: 100% 50%;
      }
      100% {
        background-position: 0% 50%;
      }
    }
  }
  