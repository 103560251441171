.custombutton{
    padding: 1.5rem 2rem;
    // width: 100%;
    width: inherit;
    white-space: nowrap;
    background: #fff;
    color: #db261f;
    border: 0px solid #db261f;
    font-weight: 600;
    box-shadow: 0 1px 3px #db261f;
    border-radius: 3px;
    transition: all 0.5s;

    &:hover{
        background: #db261f;
    color: white;  
    cursor: pointer; 
    }
}

.custombuttonactive{
    background: #db261f;
    color: white;
}

.buy-now-button{
    background: #2b1762;
    color: #fff;
    border: 0px solid #2b1762;
    box-shadow: 0 1px 3px #2b1762;
    cursor: pointer;
}

.custombutton1{
    padding: 1.2rem 1rem;
    width: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background: #f9f8f8;
    color: #db261f;
    border: 3px solid #db261f;
    font-weight: 600;
    // color: #2b1762;
    // box-shadow: inset 0 3px 25px #2b1762;
    // border-radius: 3px;
    transition: all 0.5s;
    
    &:hover{
        background: #db261f;
        color: white;   
        cursor: pointer;
        
    //     h6{
    //         font-size: 1rem;
    //   }
    }
}

.custombutton1active{
    background: #db261f;
    color: white;   
}

.custombutton2{
    padding: 1rem 1rem;
    width: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    background: #f9f8f8;
    color: #006CD9;
    border: 1px solid #006CD9;
    font-weight: 600;
    font-family: "Helvetica";
    font-size: 1rem!important;
    // color: #2b1762;
    // box-shadow: inset 0 3px 25px #2b1762;
    // border-radius: 3px;
    transition: all 0.5s;
    
    &:hover{
        background: #006CD9;
        color: white;   
        cursor: pointer;
        
    //     h6{
    //         font-size: 1rem;
    //   }
    }
}

.custombutton2active{
    background: #006CD9;
    color: white;   
}

.custombutton3{
    padding: 1rem 1rem;
    width: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    background: #f9f8f8;
    color: #db261f;
    border: 1px solid #db261f;
    font-weight: 600;
    font-family: "Helvetica";
    font-size: 1rem!important;
    // color: #2b1762;
    // box-shadow: inset 0 3px 25px #2b1762;
    // border-radius: 3px;
    transition: all 0.5s;
    
    &:hover{
        background: #db261f;
        color: white;   
        cursor: pointer;
        
    //     h6{
    //         font-size: 1rem;
    //   }
    }
}

.custombutton3active{
    background: #db261f;
    color: white;   
}

.custom-hover{
    &:hover{
        color: #db261f!important;
    }
}