.marqueeContainer {
  overflow: hidden;
  white-space: nowrap;
  display: flex;
  width: 95%!important;
  padding: 13px 0 10px 0;
  left: 85px;
  // margin: 0 auto; // Centers the container horizontally
  background-color: var(--color-background); // Optional: Add background for better visibility

  .marqueeContent {
    display: inline-flex;
    white-space: nowrap;
    animation: scroll 30s linear infinite; // Adjusted animation duration for readable speed
    animation-delay: 0s; // Ensure it starts immediately

    &:hover {
      animation-play-state: paused; // Pause animation on hover
    }

    .marqueeText {
      display: inline-block;
      padding: 0 30px;
      font-size: 14px; // Increase font size for readability
      color: var(--color-white); // Use a color variable or replace with a specific color
    }
  }
}

/* Keyframes for the scrolling effect */
@keyframes scroll {
  0% {
    transform: translateX(100%); // Start from the right corner
  }
  100% {
    transform: translateX(-100%); // Move to the left
  }
}


