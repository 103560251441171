// Variables
$primary-color: #8a0347;
$primary-color1: #f6e1efb2;
$background-color: rgba(243, 243, 243, 0.153);
$card-bg-color: #ffffff;
$hover-bg-color: #fff;
$transition-duration: 0.4s;

.Banner-background {
    background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.3)), url(.././assets/images/banner/bannerimage.jpeg);
    // background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.5)), url(../../Image/bannerimage.jpeg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    display: flex;
    justify-content: center;
    align-items: center;
    .banner-box {
        width: 90%!important;
    }
}



// Card Section
.card-background {
    // margin: 0.5vmax 0;
    width: 100%;
    padding: 3rem!important;
    background: $card-bg-color !important;
    // background: #d13939!important;
    border: 3px solid $card-bg-color !important;
    box-shadow: 0 3px 5px $card-bg-color;
    border-radius: 10px !important;
    transition: $transition-duration ease-in-out;
    z-index: 11;

    &:hover {
        transform: scale(1.1);
        background-image: linear-gradient(to top, $primary-color1, $primary-color1) !important;
        color: $card-bg-color !important;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.26);
        border: 0px solid var(--bg-color-2) !important;
        cursor: pointer;
    }

    .bann-card {
        padding: 0 0.5vmax !important;

        .banner-title {
            // height: 50px; // Set your desired height
            text-align: center!important;
            font-size: 1.5vmax!important;
            padding-top: .3vmax;
            color: $primary-color !important;
            font-weight: 500 !important;
            font-family: 'Poppins' !important;
        }

        .banner-paragraph {
            padding-top: 0.3vmax;
            font-size: 1.2vmax !important;
            color: white !important;
            font-weight: 300 !important;
            font-family: 'Poppins' !important;
        }
    }
}
// background-color: var(--bg-color-2) !important;
[data-theme='dark']{
    .Banner-background {
        background: linear-gradient(rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.3)), url(.././assets/images/banner/bannerimage.jpeg);
        // background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.5)), url(../../Image/bannerimage.jpeg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: top;
        display: flex;
        justify-content: center;
        align-items: center;
        .banner-box {
            width: 90%!important;
        }
    }
    
    // Card Section
    .card-background {
        // margin: 0.5vmax 0;
        width: 95%;
        background: var(--bg-color-2) !important;
        // background: #d13939!important;
        border: 3px solid var(--bg-color-2) !important;
        box-shadow: 0 3px 15px var(--bg-color-2);
        border-radius: 15px !important;
        transition: $transition-duration ease-in-out;
        z-index: 11;
    
        &:hover {
            transform: scale(1.1);
            // background-image: linear-gradient(to top, $hover-bg-color, $primary-color) !important;
            background-image: linear-gradient(-90deg, #db261f 0%, #2d1c70 100%)!important; 
            color: $card-bg-color !important;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.26);
            cursor: pointer;
            border: 0px solid var(--bg-color-2) !important;
            // border-image: linear-gradient(90deg, #db261f 30%, #2d1c70 100%)!important;
        }
    
        .bann-card {
            padding: 0 0.5vmax !important;
    
            .banner-title {
                text-align: center!important;
                font-size: 1.7vmax !important;
                padding-top: 1vmax;
                color: var(--color-white) !important;
                font-weight: 500 !important;
                font-family: 'Poppins' !important;
            }
    
            .banner-paragraph {
                padding-top: 0.3vmax;
                font-size: 1.2vmax !important;
                color: white !important;
                font-weight: 300 !important;
                font-family: 'Poppins' !important;
            }
        }
    }
    
}

@media screen and (min-width: 200px) and (max-width: 1198px) {
    // Card Section
.card-background {
    // margin: 0.5vmax 0;
    width: 100%;
    padding: 2rem!important;
    background: $card-bg-color !important;
    // background: #d13939!important;
    border: 3px solid $card-bg-color !important;
    box-shadow: 0 3px 5px $card-bg-color;
    border-radius: 10px !important;
    transition: $transition-duration ease-in-out;
    z-index: 11;

    &:hover {
        transform: scale(1.1);
        background-image: linear-gradient(to top, $primary-color1, $primary-color1) !important;
        color: $card-bg-color !important;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.26);
        border: 0px solid var(--bg-color-2) !important;
        cursor: pointer;
    }

    .bann-card {
        padding: 0 0.5vmax !important;

        .banner-title {
            // height: 50px; // Set your desired height
            text-align: center!important;
            font-size: 1.5rem!important;
            padding-top: .3vmax;
            color: $primary-color !important;
            font-weight: 500 !important;
            font-family: 'Poppins' !important;
        }

        .banner-paragraph {
            padding-top: 0.3vmax;
            font-size: 1.2vmax !important;
            color: white !important;
            font-weight: 300 !important;
            font-family: 'Poppins' !important;
        }
    }
}
}