.responsive-footer-nav-bar{
    display: none;
}

.responsive-custom-banner-background{
    padding: 3rem!important
}

.custom-scrollingup-tab{
    overflow: hidden!important;
}

.responsive-custom-course-side-section{
    display: flex;
    align-items: center;
    flex-direction: column;
}

@media screen and (min-width: 200px) and (max-width: 1199px) {
    .responsive-custom-course-side-section{
        display: grid;
        align-items: auto;
        flex-direction: column;
    }

    .responsive-custom-banner-background{
        padding: 0.5rem!important
    }

    .custom-scrollingup-tab{
        // overflow: scroll!important;
    }

   .responsive-custom-header-navbar{
    padding: 0px!important;
   }
   .edu-blog.blog-style-list .thumbnail {
    margin-right: 0px!important;
} 

    .responsive-custom-header-brand {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .responsive-custom-header-logo-cantainer {
        width: 80%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 19px 0px;
    }

    .responsive-custom-header-logo{
        margin-right: 0px!important;
    }

    .responsive-custom-header-category-container {
        width: 80%;
        display: flex;
        justify-content: space-between;
        // align-items: center;
    }

    .responsive-custom-header-course-link{
        padding: 0px!important;
        height: 50px!important;
        line-height: 0!important;
        left: -20px!important;
    }

    .responsive-custom-dropdown{
        left: -26px!important;
        min-width: 241px!important;
    }

    .rn-progress-parent{
        bottom: 70px;
    }

    .responsive-footer-nav-bar{
        display: block;
    }
}

@media screen and (max-width: 768px){
    .responsive-blog-card{
        width: 100%!important;
    }
    .responsive-blog-card-title{
        padding: 20px 0px;
    }

}

@media screen and (min-width: 200px) and (max-width: 767px){
    .responsive-contact-us{
        padding-top: 30px;
    }

    .edu-blog.blog-style-list{
        padding: 10px 10px 20px;
    }

    .responsive-course-custom-container{
        display: flex;
        flex-direction: column-reverse;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px){
    .responsive-contact-us{
        padding-top: 110px;
    }
}

@media screen and (min-width: 309px) and (max-width: 767px)
{
    .responsive-breadcrumb-contact-us{
        display: flex;
        align-items: center;
        height: 150px;
        justify-content: center;
    }
    .breadcrumb-style-8 .breadcrumb-right-wrapper .breadcrumb-inner .title {
        color: var(--color-white);
        font-size: 3rem;
        font-weight: 600;
    }
}
@media screen and (min-width: 200px) and (max-width: 510px){
    .responsive-career{
        font-size: 36px!important;
        font-weight: bold!important;
    }
    .responsive-course-details{
        width: 80%!important;
    }
}

@media screen and (min-width: 344px) and (max-width: 495px){
    .responsive-course-details{
        font-size: 19px!important;
    }

    .course-details-content .course-tab-content{
        margin-top: 10px!important;
    }

    .custom-badge{
        font-size: 1.5rem!important;
        
    }

    .responsive-round-icon-course-details{
        display: none!important;
    }

    .responsive-course-details{
        width: 100%!important;
        // background: red;
        border: 0px solid black!important;
        font-size: 2.6vmax!important;
        font-weight: 900!important;
        // text-align: center!important;
    }

    .responsive-padding-course-details{
        padding-left: 3rem!important;
        padding-right: 3rem!important;
    }

    .edu-breadcrumb-area{
        padding: 1px 0 42px;
    }
}

@media screen and (min-width: 200px) and (max-width: 343px){
    .responsive-course-details{
        font-size: 15px!important;
        padding-bottom: 40px;
        line-height: 27px;
        width: 70%!important;
    }
}

@media screen and (min-width: 777px) and (max-width: 991px){
    .responsive-about-page{
        padding-top: 70px;
    }
}

@media screen and (min-width: 200px) and (max-width: 767px){
    .responsive-datepicker{
        padding-top: 50px;
    }
}

@media screen and (min-width: 768px) and (max-width: 1198px){
    .responsive-datepicker{
        padding-top: 130px;
    }

    .edu-blog.blog-style-list .thumbnail {
        margin-right: 40px!important;
    } 
}

@media screen and (min-width: 200px) and (max-width: 308px)
{
    .responsive-breadcrumb-contact-us{
        display: flex;
        align-items: center;
        height: 190px;
        justify-content: center;
        font-size: 3rem!important;
    }
    .breadcrumb-style-8 .breadcrumb-right-wrapper .breadcrumb-inner .title {
        color: var(--color-white);
        font-size: 3rem;
        font-weight: 600;
    }
}
@media screen and (min-width:200px) and (max-width:767px){
    .responsive-breadcrumb-blog{
        padding-top: 100px!important;
        display: flex;
        flex-direction: column;
        height: 200px;
        justify-content: center;
    }
    .breadcrumb-style-8 .breadcrumb-right-wrapper .breadcrumb-inner .title {
        color: var(--color-white);
        font-size: 3rem;
        font-weight: 600;
    }

    .responsive-hide-campin-component{
        display: none;
    }
}